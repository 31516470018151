import React, { useContext, useState } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { getHomePageUrl, getContactPageUrl, getRegionsPageUrl } from '~/utils/routing';
import Link from 'next/link';
import { Trans } from '@lingui/react';
import { getCultureToken } from '~/utils/langUtils';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const DEFAULT_REGIONS = [
  {
    Alias: 'Ibiza',
    Country: 'Spain',
    Id: 11437,
    LatitudeNE: 38.9234098,
    LatitudeSW: 38.8912466,
    LongitudeNE: 1.4549709,
    LongitudeSW: 1.4043249,
    Name: 'Ibiza',
  },
  {
    Alias: 'Barcelona',
    Country: 'Spain',
    Id: 12951,
    LatitudeNE: 41.4695761,
    LatitudeSW: 41.320004,
    LongitudeNE: 2.2280099,
    LongitudeSW: 2.0695258,
    Name: 'Barcelona',
  },
  {
    Alias: 'Cancun',
    Country: 'Mexico',
    Id: 11435,
    LatitudeNE: 21.2129226,
    LatitudeSW: 21.0297632,
    LongitudeNE: -86.7405401,
    LongitudeSW: -86.9579266,
    Name: 'Cancún',
  },
];

const Footer = () => {
  const router = useRouter();
  const appCtx = useContext(AppContext);
  const [isBoatDetailPage, setIsBoatDetailPage] = useState(router.pathname === '/[lang]/boats/[id]');

  return (
    <>
      <FooterMain id="footer" className={isBoatDetailPage ? 'isBoatDetailPage' : ''}>
        <FooterUL className="r-footer">
          <LI className="main">
            <UL className="box">
              <LI>
                <Link href={`${getHomePageUrl(appCtx)}`} passHref={true}>
                  <a>
                    <Logo
                      alt="logo"
                      height="32"
                      width="157"
                      src={`${publicRuntimeConfig.ASSETS_PREFIX}/images/bs-logo-blue.svg`}
                    ></Logo>
                  </a>
                </Link>
              </LI>
              <LI>
                <SocialNetworkLink href="https://www.facebook.com/Boatsetter" target="_blank" rel="noopener">
                  <i className="Icon Icon-facebook"></i>
                </SocialNetworkLink>

                <SocialNetworkLink href="https://twitter.com/Boatsetter" target="_blank" rel="noopener">
                  <i className="Icon Icon-twitter"></i>
                </SocialNetworkLink>

                <SocialNetworkLink href="http://www.instagram.com/Boatsetter" target="_blank" rel="noopener">
                  <i className="Icon Icon-instagram"></i>
                </SocialNetworkLink>
              </LI>
            </UL>
          </LI>
          <LI className="main">
            <SubMenuHeading>
              <Trans id="navigation.policies" />
            </SubMenuHeading>
            <UL className="box">
              <LI>
                <MenuItem target="_blank" href="https://www.boatsetter.com/legal/terms_of_service" rel="noopener">
                  <Trans id="navigation.terms" />
                </MenuItem>
              </LI>
              <LI>
                <MenuItem target="_blank" href="https://www.boatsetter.com/legal/privacy_policy" rel="noopener">
                  <Trans id="navigation.privacy" />
                </MenuItem>
              </LI>
            </UL>
          </LI>
          <LI className="main">
            <SubMenuHeading>
              <Trans id="navigation.aboutUs" />
            </SubMenuHeading>
            <UL className="box ">
              <LI>
                <MenuItem target="_blank" href="https://www.boatsetter.com/how-it-works" rel="noopener">
                  <Trans id="navigation.howItWorks" />
                </MenuItem>
              </LI>
              <LI>
                <MenuItem target="_blank" href="https://www.boatsetter.com/about" rel="noopener">
                  <Trans id="navigation.aboutBS" />
                </MenuItem>
              </LI>
              <LI>
                <MenuItem target="_blank" href="https://www.boatsetter.com/press" rel="noopener">
                  <Trans id="navigation.press" />
                </MenuItem>
              </LI>
            </UL>
          </LI>
          <LI className="main">
            <SubMenuHeading>
              <Trans id="navigation.help" />
            </SubMenuHeading>
            <UL className="box">
              <LI>
                <MenuItem target="_blank" href="https://support.boatsetter.com" rel="noopener">
                  <Trans id="navigation.support" />
                </MenuItem>
              </LI>
              <LI>
                <Link href={`${getContactPageUrl(appCtx)}`} passHref={true}>
                  <MenuItem>
                    <Trans id="navigation.contactUs" />
                  </MenuItem>
                </Link>
              </LI>
            </UL>
          </LI>
          <LI className="main">
            <SubMenuHeading>
              <Trans id="navigation.popularRegions" />
            </SubMenuHeading>
            <UL className="box">
              {DEFAULT_REGIONS.map((region, id) => (
                <LI key={id}>
                  <Link
                    href={`/${getCultureToken(appCtx)}search?currency=${appCtx.state.currency}&latitudeSw=${
                      region.LatitudeSW
                    }&longitudeSw=${region.LongitudeSW}&latitudeNe=${region.LatitudeNE}&longitudeNe=${
                      region.LongitudeNE
                    }`}
                    passHref={true}
                  >
                    <MenuItem>
                      {region.Name}, {region.Country}
                    </MenuItem>
                  </Link>
                </LI>
              ))}
              <LI>
                <Link href={`${getRegionsPageUrl(appCtx)}`} passHref={true}>
                  <MenuItem className="link">
                    <Trans id="navigation.seeAllRegions" />
                  </MenuItem>
                </Link>
              </LI>
            </UL>
          </LI>
        </FooterUL>
      </FooterMain>
    </>
  );
};

const FooterMain = styled.div`
  position: sticky;

  display: flex;
  flex-flow: row wrap;
  padding: 50px;
  color: black;
  background-color: white;

  &.isBoatDetailPage {
    padding-bottom: 150px;

    @media screen and (max-width: 600px) {
      padding-bottom: 200px;
    }
  }

  & > * {
    flex: 1 100%;
  }
`;

const SubMenuHeading = styled.h4`
  font-size: 1.125em;
  line-height: 1.333333;
  font-weight: 600;
  color: var(--midnight);
  margin-bottom: 12px;
`;

const UL = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const LI = styled.li`
  line-height: 2em;

  &.main {
    margin-top: 2em;

    @media screen and (max-width: 600px) {
      text-align: center;
      margin-right: 1em;
      margin-left: 1em;
    }
  }
`;

const MenuItem = styled.a`
  text-decoration: none;
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  color: var(--mid-rock);
  cursor: pointer;

  &.link {
    color: var(--water);
  }

  &:hover {
    color: black;
  }
`;

const FooterUL = styled(UL)`
  display: flex;
  flex-flow: row wrap;
  max-width: 1000px;
  justify-content: center;
  margin: 0 auto;
  margin-top: -2em;
  & > * {
    flex: 1 50%;
    margin-right: 1.25em;
  }

  @media screen and (min-width: 600px) {
    flex: 2 0px;
    & > * {
      flex: 1;
    }
  }
`;

const Logo = styled.img`
  cursor: pointer;
  margin-right: 32px;
`;

const SocialNetworkLink = styled.a`
  color: #0751c2 !important;
  font-size: 1.25em;
  line-height: 1.4;
  margin-bottom: 0;
  margin-right: 1em;
`;
export default Footer;
