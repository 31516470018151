import { parseObjToUrl } from '~/utils/urlParser';
import { getCultureToken } from './langUtils';

const getHomePageUrl = (appCtx: any) => {
  return `/${getCultureToken(appCtx)}${parseObjToUrl({
    ...(appCtx.state.defaultCurrency !== appCtx.state.currency && {
      currency: appCtx.state.currency,
    }),
  })}`;
};

const getContactPageUrl = (appCtx: any) => {
  return `/${getCultureToken(appCtx)}contact${parseObjToUrl({
    ...(appCtx.state.defaultCurrency !== appCtx.state.currency && {
      currency: appCtx.state.currency,
    }),
  })}`;
};

const getRegionsPageUrl = (appCtx: any) => {
  return `/${getCultureToken(appCtx)}regions${parseObjToUrl({
    ...(appCtx.state.defaultCurrency !== appCtx.state.currency && {
      currency: appCtx.state.currency,
    }),
  })}`;
};

export const adjustSearchingPerimeter = (
  latitudeNe: number,
  latitudeSw: number,
  longitudeNe: number,
  longitudeSw: number
) => {
  const MIN_DISTANCE = 0.087 * 2; // up to six; 40 > 64km

  const xDistance = Math.abs(latitudeNe - latitudeSw);
  const yDistance = Math.abs(longitudeNe - longitudeSw);

  if (xDistance > MIN_DISTANCE || yDistance > MIN_DISTANCE)
    return {
      latitudeNe,
      latitudeSw,
      longitudeNe,
      longitudeSw,
    };

  // lat x
  // lng y

  return {
    latitudeNe: latitudeNe + Math.abs(MIN_DISTANCE - yDistance) * 0.5,
    latitudeSw: latitudeSw - Math.abs(MIN_DISTANCE - yDistance) * 0.5,
    longitudeNe: longitudeNe + Math.abs(MIN_DISTANCE - xDistance) * 0.5,
    longitudeSw: longitudeSw - Math.abs(MIN_DISTANCE - xDistance) * 0.5,
  };
};

export { getHomePageUrl, getContactPageUrl, getRegionsPageUrl };
